import { useNavigate, useParams } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { deviceService } from 'services';
import { useSuccess } from 'helpers';
import { DEVICE_FORM, PROTOCOL_TYPES } from '../helpers';
import { Button, ButtonTypes, Checkbox, Input, Paper, Select, updateFormErrors } from '@bs/techconnect-ui';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './DeviceForm.module.css';

const DeviceForm = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const isCreate = deviceId === 'new';

  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty },
    control,
    trigger,
  } = useForm({ mode: 'onTouched' });

  const fetchDevice = useCallback(() => {
    setLoading(true);
    deviceService
      .getDevice(deviceId)
      .then(({ data, status }) => {
        if (status === 200 && data) reset(data);
      })
      .finally(() => setLoading(false));
  }, [deviceId, reset]);

  useEffect(() => {
    if (!isCreate) fetchDevice();
  }, [fetchDevice, deviceId, isCreate]);

  const submit = form => {
    if (!isValid) {
      trigger();
      return;
    }
    const { deviceId, isTesting, protocol } = form;
    const body = { isTesting: !!isTesting };
    if (protocol) body.protocol = protocol;
    DEVICE_FORM.forEach(row => row.forEach(({ key }) => (body[key] = form[key])));
    const method = deviceId ? deviceService.updateDevice : deviceService.createDevice;
    setLoading(true);
    method(body, deviceId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`${deviceId ? 'Изменения сохранены' : 'Устройство создано'} `);
          navigate(`/devices`);
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    if (isDirty) {
      setExitModal(true);
    } else navigate('/devices');
    reset({});
  };

  return (
    <>
      <Paper className={styles['paper']}>
        <header className={styles['header']}>
          <Button className={styles['back-btn']} variant={ButtonTypes.IR} onClick={close}>
            <Back />
          </Button>
          <h3>{isCreate ? 'Создание ' : 'Редактирование'} устройства</h3>
          <Button className={styles['submit-btn']} disabled={!isValid} onClick={() => handleSubmit(submit)()}>
            {isCreate ? `Создать устройство` : 'Сохранить изменения'}
          </Button>
        </header>
      </Paper>
      <div className={styles['fields-wrapper']}>
        {DEVICE_FORM.map((row, rIndex) => (
          <div key={rIndex} className={styles['fields-row']}>
            {!rIndex && (
              <Controller
                control={control}
                name="protocol"
                render={({ field: { onChange, value } }) => (
                  <Select
                    className={styles['field']}
                    label="Протокол"
                    placeholder="Не выбрано"
                    list={Object.keys(PROTOCOL_TYPES).map(id => ({ id, title: PROTOCOL_TYPES[id] }))}
                    selected={value}
                    onSelect={onChange}
                    disabled={loading}
                  />
                )}
              />
            )}
            {row.map(({ key, title, options, readOnly, list }) =>
              !list ? (
                <Input
                  key={key}
                  label={title}
                  className={styles['field']}
                  register={register(key, options)}
                  error={errors[key]}
                  disabled={loading}
                  readOnly={!isCreate && readOnly}
                />
              ) : (
                <Controller
                  key={key}
                  control={control}
                  name={key}
                  rules={options}
                  render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
                    return (
                      <Select
                        className={styles['field']}
                        label={title}
                        list={list}
                        selected={value}
                        onBlur={onBlur}
                        error={error}
                        onSelect={onChange}
                      />
                    );
                  }}
                />
              ),
            )}
            {!!(rIndex === DEVICE_FORM.length - 1) && (
              <>
                <div className={styles['empty']} />
                <div className={styles['empty']} />
              </>
            )}
          </div>
        ))}
        <Controller
          control={control}
          name="isTesting"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              className={styles['field']}
              label="Тестовое устройство"
              checked={value}
              onChange={() => onChange(!value)}
              disabled={loading}
            />
          )}
        />
      </div>
      {<AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigate(`/devices`)} />}
    </>
  );
};
export default DeviceForm;
