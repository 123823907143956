import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, NavLink } from 'react-router-dom';

import { iotDevicesService } from 'services';
import { useSuccess } from 'helpers';
import { COLUMNS, FILTERS, SIM_STATE_ENUM } from './helpers';
import { PROTOCOL_TYPES } from 'components/Devices/helpers';
import {
  ListFilters,
  Table,
  Copy,
  Button,
  Popper,
  ButtonTypes,
  Indicator,
  IndicatorTypes,
  pageSize,
  shortString,
} from '@bs/techconnect-ui';
import { ReactComponent as Card } from 'icons/card.svg';
import { ReactComponent as HasLicense } from 'icons/check.svg';
import { ReactComponent as NoLicense } from 'icons/cross.svg';

import styles from './IotDevices.module.css';

const IotDevices = () => {
  const { setSuccess } = useSuccess();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});

  const fetchIotDevices = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`iotDevicesFilters`) || '{}') || {};
    const { filter = false, ...queryFilter } = filterData;
    setLoading(true);
    iotDevicesService
      .getIotDevices({ action: 'getDevices', params: { page, pageSize, ...(filter && queryFilter) } })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchIotDevices();
  }, [fetchIotDevices, filters]);

  const getSimStatus = simState => {
    const isActive = simState === SIM_STATE_ENUM.active;
    const { neutral, success } = IndicatorTypes;
    return (
      <Indicator className={styles[simState]} status={isActive ? success : neutral}>
        {simState[0].toUpperCase() + simState.slice(1)}
      </Indicator>
    );
  };
  const handler = row => ({
    ...row,
    id: (
      <Copy value={row.deviceId} callback={() => setSuccess('DeviceId скопирован')}>
        {shortString(row.deviceId)}
      </Copy>
    ),
    esn: (
      <Copy value={row.esn} callback={() => setSuccess('ESN скопирован')}>
        {row.esn}
      </Copy>
    ),
    iccid: (
      <Copy value={row.iccid} callback={() => setSuccess('ICCID скопирован')}>
        {row.iccid}
      </Copy>
    ),
    msisdn: (
      <Copy value={row.msisdn} callback={() => setSuccess('MSISDN скопирован')}>
        {row.msisdn}
      </Copy>
    ),
    protocol: PROTOCOL_TYPES[row.protocol],
    simState: getSimStatus(row.simState),
    hasLicense: row.hasLicense ? <HasLicense className={styles['has-license']} /> : <NoLicense />,
    megafonAccount: row.megafonAccount,
    edit: (
      <Popper popup={'Перейти в карточку устройства'}>
        <NavLink to={`/iotDevices/${row.deviceId}`}>
          <Button variant={ButtonTypes.IT}>
            <Card />
          </Button>
        </NavLink>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Устройства IoT платформы"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        updateList={fetchIotDevices}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="deviceId"
        handler={handler}
      />
    </>
  );
};

export default IotDevices;
