import { ERRORS } from '@bs/techconnect-ui';

export const FILTERS = [
  { key: 'deviceId', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'vehicleId', title: 'VehicleId' },
];

export const COLUMNS = [
  { key: 'id', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'vehicleId', title: 'VehicleId' },
  { key: 'lastActivity', title: 'Последняя активность' },
  { key: 'status', title: 'Status' },
  { key: 'megafonAccount', title: 'Учёт. запись Megafon' },
  { key: 'telemetry', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const POSSIBLE_MEGAFON_ACCOUNTS = ['Rusim', 'Technolabs'];

export const DEVICE_FORM = [
  [
    {
      key: 'megafonAccount',
      title: 'Учёт. запись Megafon',
      options: { required: ERRORS.REQUIRED('Учёт. запись Megafon') },
      readOnly: false,
      list: POSSIBLE_MEGAFON_ACCOUNTS.map(id => ({ id, title: id })),
    },
    { key: 'esn', title: 'ESN', options: { required: ERRORS.REQUIRED('ESN') }, readOnly: true },
    { key: 'msisdn', title: 'MSISDN', options: {} },
  ],
  [
    { key: 'iccid', title: 'ICCID', options: { required: ERRORS.REQUIRED('ICCID') } },
    { key: 'imei', title: 'IMEI', options: { required: ERRORS.REQUIRED('IMEI') } },
    { key: 'moduleNumber', title: 'Module Number', options: {} },
    { key: 'hardwareNumber', title: 'Hardware Number', options: {} },
  ],
  [
    { key: 'bootloaderNumber', title: 'Bootloader Number', options: {} },
    { key: 'strategyNumber', title: 'Strategy Number', options: {} },
    { key: 'calibrationNumber', title: 'Calibration Number', options: {} },
  ],
];

export const PROTOCOL_TYPES = {
  CCTCP: 'CCTCP',
  VegaWialonIPS20: 'Vega Wialon IPS 2.0',
};
